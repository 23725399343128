import React from 'react'
import { Body } from 'components/typography'
import { RouterPaths } from 'constants/paths/routerPaths'
import Button from 'components/ui/button/Button'
import Footer from 'components/shared/footer/Footer'
import styles from 'components/pages/404.module.scss'

const NotFound: React.FC = () => (
  <>
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.numbers}>404</p>
        <span className={styles.title}>Something went wrong</span>
        <Body className={styles.subtitle}>
          Sorry, the page you are looking for doesn’t exist or has been moved.
          Please check the URL again or let us take you back to the homepage
        </Body>

        <Button linkTo={RouterPaths.HOME} isSecondary isTransparent>
          Back to Home
        </Button>
      </div>
    </div>
    <Footer classname={styles.footer} />
  </>
)

export default NotFound
