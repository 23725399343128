import React from 'react'
import MainContainer from 'components/layout/main-container/MainContainer'
import { Body } from 'components/typography'
import Mail from 'images/icons/message-icn.svg'
import Logo from 'images/icons/kruze-consulting-icn.svg'
import cs from 'classnames'
import styles from './Footer.module.scss'

const Footer: React.FC<{ classname?: string }> = ({ classname }) => (
  <div className={cs(styles.wrapper, classname)}>
    <MainContainer className={styles.mainContainer}>
      <div className={styles.container}>
        <Logo />
        <div>
          <Body
            as='a'
            className={styles.contacts}
            href='mailto: support@kruzeconsulting.com'
          >
            <Mail /> support@kruzeconsulting.com
          </Body>
        </div>
      </div>
      <Body className={styles.copyright}>
        Copyright © Kruze Consulting 2021
      </Body>
    </MainContainer>
  </div>
)

export default Footer
